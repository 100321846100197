/** @jsx jsx */
import React from "react"
import styled from "@emotion/styled"
import { jsx, Container, Heading, Text, Button } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export const Hero = data => {
  return (
    <StyledSection sx={{ variant: `styles.hero` }}>
      <BgImage />
      <Content {...data} />
    </StyledSection>
  )
}

const BgImage = () => {
  const { hero, hero1, hero2, hero3, hero4 } = useStaticQuery(query)
  const sources = [
    hero.childImageSharp.fluid,
    { ...hero1.childImageSharp.fluid, media: `(min-width: 40em)` },
    { ...hero2.childImageSharp.fluid, media: `(min-width: 56em)` },
    { ...hero3.childImageSharp.fluid, media: `(min-width: 64em)` },
    { ...hero4.childImageSharp.fluid, media: `(min-width: 75em)` },
  ]
  return (
    <>
      <StyledBackgroundImage
        Tag="div"
        fluid={sources}
        sx={{ variant: `styles.hero.background` }}
      />
      <Overlay sx={{ variant: `styles.hero.background.overlay` }} />
    </>
  )
}

const Content = ({ title, copy }) => (
  <StyledContent sx={{ variant: `styles.hero.content` }}>
    <Heading as="h1">{title()}</Heading>
    <Text as="p" variant="paragraph">
      {copy()}
    </Text>
    <Button
      onClick={() => {
        window.open(
          "https://api.whatsapp.com/send?phone=5215527156263&text=¡Hola! Quiero cotizar un flete",
          "_blank"
        )

        typeof window !== "undefined" && window.gtag("event", "conversion")
      }}
    >
      Cotizar
    </Button>
  </StyledContent>
)

const StyledSection = styled.section`
  display: grid;
`

const StyledContent = styled(Container)`
  z-index: 10;
  align-self: center;
  display: grid;
  align-content: center;
  justify-content: center;
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute !important;
  width: 100%;
  background-color: transparent;
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
`

const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero1: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 896) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero2: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero3: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero4: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1520) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
