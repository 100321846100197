/** @jsx jsx */
import React from "react"
import { jsx, Container, Heading, Text } from "theme-ui"
import styled from "@emotion/styled"

export const Testimonials = () => (
  <section sx={{ variant: `styles.testimonials` }}>
    <StyledContainer sx={{ variant: `styles.testimonials.container` }}>
      <Heading as="h4" variant="title">
        Lo que dicen nuestros clientes
      </Heading>
      <Text as="blockquote" variant="quote">
        "Cotize y contrate un flete que necesitaba para mover unas cosas a mi
        departamento,
        <br /> todo fue bueno, el servicio, la entrega y el precio, realmente
        los recomiendo "
      </Text>
      <Text as="cite" variant="cite.author">
        Oliver C.
      </Text>

      <Text as="cite" variant="cite.service">
        Flete CDMX
      </Text>
    </StyledContainer>
  </section>
)

const StyledContainer = styled(Container)`
  display: grid;
`
