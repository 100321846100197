/** @jsx jsx */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { jsx, Container, Heading, Text, Button } from "theme-ui"
import styled from "@emotion/styled"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export const Services = () => {
  const { atizapan, cdmx, mexico } = useStaticQuery(query)

  return (
    <StyledContainer sx={{ variant: `styles.services` }}>
      <Card
        fluid={atizapan.childImageSharp.fluid}
        title="Fletes Atizapan"
        description="¿Necesitas mover un refrigerador en Atizapan? Nosotros te ayudamos"
      />
      <Card
        fluid={cdmx.childImageSharp.fluid}
        title="Fletes CDMX"
        description="¿Necesitas entregar productos a un nuevo cliente? Nosotros te ayudamos"
      />
      <Card
        fluid={mexico.childImageSharp.fluid}
        title="Fletes Estado de México"
        description="¿Compraste una sala y necesitas llevarla a tu departamento? Nosotros te ayudamos"
      />
    </StyledContainer>
  )
}

const Card = ({ fluid, title, description }) => (
  <CardContainer sx={{ variant: `styles.services.card` }}>
    <BackgroundImage fluid={fluid} sx={{ variant: `styles.services.image` }} />
    <Heading as="h2" variant="subtitle">
      {title}
    </Heading>
    <Text as="p">{description}</Text>
    <Button
      onClick={() => {
        window.open(
          "https://api.whatsapp.com/send?phone=5215527156263&text=¡Hola! Quiero cotizar un flete",
          "_blank"
        )

        typeof window !== "undefined" && window.gtag("event", "conversion")
      }}
    >
      Cotizar
    </Button>
  </CardContainer>
)

const StyledContainer = styled(Container)`
  display: grid;
`

const CardContainer = styled.div`
  display: grid;
`

const query = graphql`
  query {
    atizapan: file(relativePath: { eq: "atizapan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cdmx: file(relativePath: { eq: "cdmx.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mexico: file(relativePath: { eq: "mexico.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
