/** @jsx jsx */
import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import { jsx, Container, Heading, Text } from "theme-ui"

export const Features = ({ copies }) => {
  const { phone, phone1 } = useStaticQuery(query)
  const sources = [
    phone.childImageSharp.fluid,
    { ...phone1.childImageSharp.fluid, media: `(min-width: 40em)` },
  ]

  return (
    <StyledContainer sx={{ variant: `styles.features` }}>
      <FeatureList sx={{ variant: `styles.features.firstList` }}>
        <Copy {...copies[0]} />
        <Copy {...copies[1]} />
      </FeatureList>
      <ImageContainer sx={{ variant: `styles.features.image` }}>
        <Img fluid={sources} />
      </ImageContainer>
      <FeatureList sx={{ variant: `styles.features.lastList` }}>
        <Copy {...copies[2]} />
        <Copy {...copies[3]} />
      </FeatureList>
    </StyledContainer>
  )
}

const Copy = ({ title, text }) => (
  <>
    <Heading as="h2" variant="subtitle">
      {title()}
    </Heading>
    <Text as="p">{text}</Text>
  </>
)

const FeatureList = styled.div`
  display: grid;
  grid-template-rows: "1fr 1fr";
`

const StyledContainer = styled(Container)`
  display: grid;
`

const ImageContainer = styled.div`
  display: grid;
  align-content: center;
`

const query = graphql`
  query {
    phone: file(relativePath: { eq: "smartphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phone1: file(relativePath: { eq: "smartphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
